import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SectionLayout from '../components/SectionLayout';
import SEO from '../components/seo';

class PressKit extends React.Component {

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, 'career']}
        />
        <SectionLayout title="Press Kit">
        
        </SectionLayout>
      </Layout>
    );
  }
}

export default PressKit;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
